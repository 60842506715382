<template>
  <div
    class="topic-deatil-container"
    :class="{
      forbidden,
    }"
  >
    <div class="topic-detail-header-container">
      <template v-if="detail">
        <div class="user-header-container" v-if="topicEditAuth">
          <div class="header__title">
            <p># {{ detail.title }}</p>
          </div>
          <div class="header__info">
            <p class="info__text">{{ scopeTitle }}</p>
            <div class="edit-bt" @click="onEditTopic">
              <svg-icon icon-class="icon-pen"></svg-icon>
            </div>
          </div>
        </div>
        <div class="friend-header-container" v-else>
          <div class="header__title header-main-body">
            <p class="header__item">{{ groupID > 0 ? "小组" : "亲友" }}</p>
            <p class="header__item">主题</p>
            <p class="header__item">我回应/分享</p>
          </div>
          <div class="header__info header-main-body">
            <p class="header__item substr">
              {{ pageTitle }}
            </p>
            <p class="header__item"># {{ detail.title }}</p>
            <p class="header__item">
              <strong
                >{{ userUnlockPostCount }}<span>/{{ detail.totalPostCount }}</span></strong
              >
            </p>
          </div>
        </div>
      </template>
    </div>
    <div class="topic-list-wrapper">
      <van-list
        class="list-wrapper__inner"
        v-model="requesting"
        :finished="finished"
        :immediate-check="false"
      >
        <topic-item
          v-for="(item, index) in lists"
          theme="topic"
          :key="index"
          :info="item"
          :queue="index"
          :lock-all="role === 'user' || userLockAll"
          :call-post-del="handlerPostDelete"
          :is-stranger="isStranger"
          :is-show-latest="isShowLatestHint"
          :groupMangers="groupMangers"
          :renderType="renderType"
          @postDel="onPostDel"
          @postReply="onPostReply"
          @click.native="(e) => goDetail(item.id, e)"
        ></topic-item>
      </van-list>
      <!-- 回复表单 -->
      <reply-form
        theme="topic"
        @afterReply="onAfterReply"
        @cancel="onReplyFormCancel"
        :post-link="replyPostLink"
        :ex-info="exInfo"
        :is-author="isAuthor"
      ></reply-form>
    </div>
    <!-- 标语 -->
    <slogan text="没有人是一座孤岛"></slogan>
    <!-- 添加按钮 -->
    <add-bt
      v-if="canIAddPost"
      @click.native="onCreatePost"
      icon="icon-plus-square"
      color="#33658A"
    ></add-bt>
    <!-- 底部按钮 -->
    <div class="group__footer-fixed-nav">
      <!-- <div @click="goBack" class="nav__item"><strong>返回</strong></div> -->
      <share-bottom></share-bottom>
    </div>
  </div>
</template>

<script>
import topicPostMixin from '@/mixins/topic-post';
import forbiddenMixin from '@/mixins/forbidden';
import { mapActions, mapMutations, mapState } from 'vuex';
import wxtool from '@/utils/wx-tool';
import { TOPIC_SCOPE_MAP } from '@/utils/dict';
import addBt from '../common/_add-bt.vue';
import slogan from '../common/_slogan.vue';
import topicItem from '../timeline/_item.vue';
import replyForm from '../common/_reply-form.vue';
import shareBottom from '../common/_share-bottom.vue';

export default {
  name: 'page-topic-detail',
  props: ['topicid', 'id'],
  components: {
    shareBottom,
    slogan,
    topicItem,
    replyForm,
    addBt,
  },
  mixins: [topicPostMixin, forbiddenMixin],
  data() {
    return {
      requesting: false,
      finished: false,
      page: 1,
      totalPage: 1,
      isStranger: true,
      detail: null,
      group: null,
    };
  },
  computed: {
    ...mapState(['user', 'myGroups']),
    topicEditAuth() {
      return this.isAuthor || this.isGroupAuthor || this.isGroupManager;
    },
    role() {
      return this.id > 0 ? 'friend' : 'user';
    },
    friendid() {
      return this.id;
    },
    shareComplete() {
      return this.user && this.detail && ((this.groupID > 0 && this.group) || this.groupID === 0);
    },
    scopeTitle() {
      if (this.detail) {
        const info = TOPIC_SCOPE_MAP.find((item) => Number(item.val) === Number(this.detail.scope));
        return this.groupID === 0 ? info?.name : '';
      }
      return '';
    },
    canIAddPost() {
      if (this.groupID > 0) {
        const members = this.group?.members;
        return members
          ? members.findIndex((item) => Number(item.uid) === Number(this.user?.uid)) >= 0
          : false;
      }
      return Number(this.user?.uid) === Number(this.detail?.author.uid);
    },
    isAuthor() {
      const uid = Number(this.user ? this.user.uid : 0);
      const authorUid = Number(this.detail && this.detail.author ? this.detail.author.uid : 0);
      return uid === authorUid && uid > 0;
    },
    isShowLatestHint() {
      return this.detail ? this.detail.totalPostCount - this.userUnlockPostCount > 1 : true;
    },
    pageTitle() {
      const that = this;
      if (that.groupID > 0 && that.group) {
        return that.group.title;
      }
      if (that.detail) {
        return that.$options.filters.comment(that.detail.author.nickname, that.detail.author.uid);
      }
      return '';
    },
    topicID() {
      return this.$route.params.topicid;
    },
    isGroupAuthor() {
      return (
        this.groupID > 0
        && this.user
        && this.group
        && Number(this.user.uid) === Number(this.group.author.uid)
      );
    },
    isGroupManager() {
      return (
        this.groupID > 0
        && this.user
        && this.group
        && this.group.admins.findIndex((item) => Number(item.uid) === Number(this.user.uid)) >= 0
      );
    },
  },
  watch: {
    $route: {
      handler() {
        this.pageInit();
      },
      immediate: true,
    },
    shareComplete(val) {
      console.log(val);
      const that = this;
      if (val) {
        // 分享文案
        const uri = that.groupID > 0 ? `group/${that.groupID}/topic/` : 'topic/';
        const typeText = that.groupID > 0 ? '主题' : '话题';
        const link = `${process.env.VUE_APP_BASE}${uri}${that.topicID}`;
        wxtool.share(
          {
            title: `${that.user.nickname} 和你分享了${typeText} #${this.detail?.title}`,
            desc:
              that.groupID > 0
                ? `来自小组：${that.group?.title}`
                : '建设你 建设我 建设我们共同的时光',
            link,
            imgUrl:
              that.groupID > 0
                ? that.$root.$options.filters.parseImage(that.group?.cover)
                : that.detail?.author.headimgurl,
          },
          this.$route.fullPath,
        );
      }
    },
  },
  mounted() {
    const that = this;
    if (that.groupID) {
      that.getGroup(that.groupID).then(() => {
        that.group = that.myGroups.get(Number(that.groupID));
      });
    }
  },
  destroyed() {},
  methods: {
    ...mapMutations(['OPEN_REPLY_FORM', 'CLOSE_REPLY_FORM']),
    ...mapActions(['getGroup']),
    hasViewd() {
      const that = this;
      if (that.role === 'friend' || that.groupID > 0) {
        const postids = that.lists.map((item) => item.id);
        if (postids.length > 0) {
          that.viewTopicPosts(that.topicid, null, that.user.uid);
        }
      }
    },
    pageInit() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        const p = Promise.all([that.getDetail(), that.getPosts()]);
        p.then((res) => {
          const detail = res[0].data;
          const lists = res[1].data;
          that.detail = detail;
          that.isStranger = that.detail.isStranger;
          that.userUnlockPostCount = detail.userUnlockedCount;
          that.lists.push(...lists);
          that.hasViewd();
          that.finished = true;
        })
          .catch((e) => {
            if (e.status === 403 || e.status === 500) {
              this.notifyForbidden(
                'topic',
                e.message,
                '查看发布人',
                `/friend/${e.data.author_uid}`,
              );
            }
            if (e.status === 404) {
              this.notifyNotFound('该话题已消失', `/friend/${e.data.author_uid}`);
            }
          })
          .finally(() => {
            that.requesting = false;
            that.finished = true;
          });
      }
    },
    getDetail() {
      return this.$request.get(`api/user/topic/${this.topicid}`);
    },
    getPosts() {
      let url;
      if (this.groupID > 0) {
        url = `api/group/${this.groupID}/topic/posts/${this.topicid}`;
      } else {
        url = this.role === 'user'
          ? `api/user/topic/posts/${this.topicid}`
          : `api/user/${this.friendid}/topic/posts/${this.topicid}`;
      }

      return this.$request.get(url, {
        page: this.page,
      });
    },
    onEditTopic() {
      const link = this.groupID > 0
        ? `/group/${this.groupID}/topic/${this.topicid}/edit`
        : `/topic/edit/${this.topicid}`;
      this.$router.push({
        path: link,
      });
    },
    goDetail(postid, e) {
      const tagName = e.target.tagName.toLowerCase();
      if (tagName !== 'a') {
        const url = this.groupID > 0
          ? `/group/${this.groupID}/topic/post/${postid}`
          : `/topic/post/${postid}`;
        this.$router.push({
          path: url,
        });
      }
    },
    onCreatePost() {
      this.generateAfterCreatePostLink(this.$route.fullPath);
      let path = `/topic/post/edit/${this.topicid}?topicname=${this.detail.title}`;
      if (this.groupID > 0) {
        path = `${path}&groupID=${this.groupID}`;
      }
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style></style>
